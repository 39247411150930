<template>
  <a @click.prevent="scrollTo(to)" :href="`#${to}`"><slot/></a>
</template>

<script setup>
import {defineProps} from 'vue';
defineProps({
  to: String
});
const scrollTo = id => document.getElementById(id)?.scrollIntoView({block: 'center', behavior: 'smooth'});
</script>

<style lang="scss">
.linkToWhyNotToUse {
  color: rgba(255,255,255,.75) !important;
}
</style>