<template>
  <div class="top">
    <router-view/>
    <div class="header">
      <div>
        <SwitchLanguage/>
      </div>
      <div class="right">
        <a href="https://www.pixelpublic.de/" target="_blank" rel="noreferrer" class="logo">
          <img src="./assets/pixelpublic_logo.svg" alt="pixelpublic GmbH">
        </a>
      </div>
    </div>
  </div>
  <div class="footer">
    <a href="https://54gradsoftware.de" class="licence-link" target="_blank" rel="noreferrer">54 Grad Software</a>
    <div class="right">
      <a href="https://www.pixelpublic.de/impressum/" target="_blank" rel="noreferrer">{{ $t('footer.imprint') }}</a>
      <a href="https://www.pixelpublic.de/datenschutz/" target="_blank" rel="noreferrer">{{ $t('footer.privacyNotice') }}</a>
    </div>
  </div>
  <MatomoTracking/>
</template>

<script setup>
import SwitchLanguage from '@/components/SwitchLanguage.vue';
import MatomoTracking from '@/components/MatomoTracking';
import { watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';

const route = useRoute();
const i18n = useI18n();

watch(() => route.params.locale, locale => {
  i18n.locale._setter(locale.toString());
});

</script>

<style lang="scss">
@import 'https://use.typekit.net/ved2rqy.css';

html, body{
  height: 100%;
  margin: 0;
  background-color: #1e2129;
  color: rgba(255,255,255,.75);


  * {
    font-family: interstate, sans-serif;
  }

  input, button {
    font-family: interstate, sans-serif;
    font-weight: 400;
    font-style: normal;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: interstate, sans-serif;
    font-weight: 600;
    font-style: normal;
  }
}
#app {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  min-height: 100%;
  line-height: 1.4;
  .top{
    .header{
      padding: 1rem 2rem;
      box-sizing: border-box;
      background-color: #313336;
      color: #fff;
      font-weight: bold;
      font-size: 1.2rem;
      position: fixed;
      top: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: 4rem;
      .right{
        display: flex;
        flex-flow: row;
        align-items: center;
        .logo{
          width: auto;
          height: 50px;
          display: flex;
          margin-right: 1rem;
        }
      }
    }
  }
  .footer{
    justify-content: space-between;
    display: flex;
    padding: 1rem 2rem;
    background-color: #222;
    color: #fff;

    a{
      color: #fff;
      text-decoration: none;

      &.licence-link {
        color: rgba(255,255,255,.4);
        text-decoration: none;
      }
    }
    .right{
      display: flex;
      flex-flow: row;
      align-items: center;

      a {
        margin-left: 1rem;
        &:hover {
          color: rgba(255,255,255,.5);
        }
      }
    }
  }
  .button{
    background-color: #9A9269;
    border:none;
    -webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
    -o-transition: all cubic-bezier(.4,0,.2,1) .4s;
    transition: all cubic-bezier(.4,0,.2,1) .4s;

    &:hover {
      background-color: #B5AC80;
    }
    padding: 1rem;
    color: #fff;
    border-radius: 0.5rem;
    width: fit-content;
    cursor: pointer;
    display: block;
    text-decoration: none;
    margin: auto;
  }

  a {
    color: #9A9269;

    -webkit-transition: all cubic-bezier(.4,0,.2,1) .4s;
    -o-transition: all cubic-bezier(.4,0,.2,1) .4s;
    transition: all cubic-bezier(.4,0,.2,1) .4s;

    &:hover {
      color: #fff;
    }
  }

  h1,h2,h3,h4,h5 {
    color: #fff;
  }
}
</style>
